import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCn from '@/locales/zh-CN.json'
import enUS from '@/locales/en-US.json'

Vue.use(VueI18n)

const defaultLang = getDefaultLang();
const Message = {
  'en-US': enUS,
  'zh-CN': zhCn
}

const i18n = new VueI18n({
  locale: defaultLang || 'en-US',
  messages: Message,
  fallbackLocale: 'en-US',
  silentFallbackWarn: true
})

/**
 * 获取默认语言
 * @returns {string|string}
 */
export function getDefaultLang() {
  const lang = localStorage.getItem('lang');
  return lang || 'en-US';
}

/**
 * 获取语言下的文本
 * @param text
 * @param lang
 */
export function getLangMessage(text, lang = '') {
  const nextLang = lang || getDefaultLang();

  return Message[nextLang][text] || text;
}

export default i18n